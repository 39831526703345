// 服务地址;
let option = {
  analysis: "/api/data-analysls-platform", //analysis
  // othaer:'http://39.106.167.142:19090/mock/101/'
  project:'/api/project'
};

//请求地址
export default {
  //数据分析平台--Dashboard & 报表中心
  DATA_ANALYSIS_DSBLIST: option.analysis + "/web/dashboard/list",  //获取dashboard信息&报表中心
  DATA_ANALYSIS_DSBSELECT: option.analysis + "/report/management/getClassifyList",  //获取dashboard下拉框&报表中心
  DATA_ANALYSIS_DROPDOWNBOX: option.analysis + '/label/dropDownBox',// 获取标签
  DATA_ANALYSIS_ADDLOG: option.analysis + '/log/addLog',// 添加报告预览日志
  DATA_ANALYSIS_KANBAN: option.analysis + "/web/kanban/center/list",  //获取看板中心
  DATA_ANALYSIS_CLASSIFY_TYPE_LIST: option.analysis + "/classification/manage/classify/type/list/",  //根据分类类型查询分类

  // 分析报告
  REPORT_LIST: option.analysis + '/web/analysl/report/list',// 获取分析报告数据

  //数据分析平台--标签
  DATA_ANALYSIS_LABLIST: option.analysis + '/label/query',// 获取标签
  DATA_ANALYSIS_LABINSERT: option.analysis + '/label/insert',// 新增标签
  DATA_ANALYSIS_LABMODIFIED: option.analysis + '/label/modified',// 修改标签
  DATA_ANALYSIS_LABDELETE: option.analysis + '/label/delete',// 删除标签

  //数据分析平台--日志
  DATA_ANALYSIS_JOURNAL: option.analysis + "/log/query",  //获取操作日志
  //数据分析平台--分类
  DATA_ANALYSIS_CLASSIFICATION: option.analysis + "/classification/manage/list",  //获取分类信息
  DATA_ANALYSIS_CLASSINSERT: option.analysis + "/classification/manage/insert",  //新增分类信息
  DATA_ANALYSIS_CLASSUPDATE: option.analysis + "/classification/manage/update",   //修改分类信息
  DATA_ANALYSIS_CLASSDELETED: option.analysis + "/classification/manage/deleted/",  //删除分类信息

  //数据分析平台--报告
  DATA_ANALYSIS_MANAGEMENTDATA: option.analysis + "/report/management/list",  //获取平台
  DATA_ANALYSIS_MANAGEMENTCLASSIFY: option.analysis + "/report/management/getClassifyList",  //获取类型
  DATA_ANALYSIS_MANAGEMENTLIST: option.analysis + "/report/management/list",  //获取全部报告
  DATA_ANALYSIS_MANAGEMENTINSERT: option.analysis + "/report/management/insert",  //新增报告
  DATA_ANALYSIS_MANAGEMENTUPDATE: option.analysis + "/report/management/update",  //修改报告
  DATA_ANALYSIS_MANAGEMENTDELETED: option.analysis + "/report/management/deleted/",  // 删除报告
  DATA_ANALYSIS_MANAGEMENTFINDBYID: option.analysis + "/report/management/findById/",  // 查询报告
  DATA_ANALYSIS_MANAGEMENTSORT: option.analysis + "/report/management/setSequence/",  //排序
  GETMETADATALIST: option.analysis + "/report/management/getMetadataList",  //获取列表中报告类型/报告平台

  //数据分析平台--用户
  DATA_ANALYSIS_USERINFO: option.analysis + "/user/management/list",  //获取用户信息
  DATA_ANALYSIS_USERPHONE: option.analysis + "/user/management/getUserInfoByNumber/",  //获取用户手机号
  DATA_ANALYSIS_USERINSERT: option.analysis + "/user/management/insert",  //添加用户信息
  DATA_ANALYSIS_USERFINDBYID: option.analysis + "/user/management/findById/",  //获取当前修改用户信息
  DATA_ANALYSIS_USERUPDATE: option.analysis + "/user/management/update",  //修改用户信息
  DATA_ANALYSIS_USERDELETED: option.analysis + "/user/management/deleted/",  //删除用户信息

  //运营数据管理
  USERROLE_PROJECTLIST:option.project + '/saas/project/userRole/projectList',//查询i友工作台权限项目
  OPERATE_DATE_MANAGE_DETAIL: option.analysis + "/webb/operate-date-manage/detail",  //运营数据管理-详情
  OPERATE_DATE_MANAGE_SAVE: option.analysis + "/webb/operate-date-manage/save",  //运营数据管理-保存

};

