<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <a-spin :spinning="$store.state.loading.loading">
        <router-view />
      </a-spin>
    </div>
  </a-config-provider>
</template>
<script>
//默认国家化 设置中文
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
