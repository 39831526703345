import {
  getReports,
  getReoprtlist,
  getClassify,
  addReport,
  deleteReport,
  queryReport,
  updateReport,
  setSequencereport,
  getMetadataList
} from "@/api/dataAnalysis/allReports";
const allReports = {
  namespaced: true,
  state: () => ({
    list: [],
    reports: [],//平台下拉数据
    classify: [],
    total: 0,
    seeQuery: [],
    reportTypeList: []//类型下拉数据
  }),
  mutations: {
    SET_LIST(state, arg) {
      state.list = arg;
    },
    SET_REPORTS(state, arg) {
      state.reports = arg;
    },
    SET_CLASSIFY(state, arg) {
      state.classify = arg;
    },
    SET_TOTAL(state, arg) {
      state.total = arg;
    },
    SET_SEEQUERY(state, arg) {
      state.seeQuery = arg;
    },
    SET_TYPE_LIST(state, arg) {
      state.reportTypeList = arg;
    },
  },
  actions: {
    //列表
    reportCollection({ commit, state }, data) {
      if (data.pageNum) {
        getReoprtlist(data).then((res) => {
          if (res.code == 200) {
            res.data.list.forEach(item => {
              item.reportStatus = item.reportStatus == 0 ? "启用" : "禁用";
              state.reports.forEach(i => {
                if (item.reportPlatform === i.id) {
                  item.reportPlatform = i.commonsValueName
                }
              })
              //类型展示转换
              state.reportTypeList.forEach(i => {
                if (item.reportType == i.commonsValue) {
                  item.reportType = i.commonsValueName
                }
              })
            })
            commit("SET_LIST", res.data.list);
            commit("SET_TOTAL", res.data.total);
          }
        });
      }

    },
    //平台
    platformCollection({ commit }, data) {
      getMetadataList(data).then((res) => {
        if (data.commonsKey === 'REPORT_PLATFORM') {
          if (res.code == "200") {
            commit("SET_REPORTS", res.data);
          }
        } else {
          if (res.code == "200") {
            commit("SET_TYPE_LIST", res.data);
          }
        }

      });
    },
    //分类
    getAllclassify({ commit }, data) {
      getClassify(data).then((res) => {
        console.log(res);
        if (res.code == "200") {
          commit("SET_CLASSIFY", { ...res.data });
        }
      });
    },
    //新增
    addReportList({ dispatch }, { form, cb, cbwarning }) {
      addReport(form).then((res) => {
        console.log(res);
        if (res.code !== "200") {
          cbwarning(res.msg);
        } else {
          cb("添加成功");
          dispatch("reportCollection", { pageNum: form.pageNum, pageSize: form.pageSize });
        }
      });
    },
    //修改
    updateReportList({ dispatch }, { form, cb, cbwarning }) {
      updateReport(form).then((res) => {
        console.log(res);
        if (res.code !== "200") {
          cbwarning(res.msg);
        } else {
          cb("修改成功");
          dispatch("reportCollection", { pageNum: form.pageNum, pageSize: form.pageSize });
        }
      });
    },
    //删除
    uninstallReport({ dispatch }, { id, cb, pageNum, pageSize, cbError }) {
      deleteReport(id).then((res) => {
        if (res.msg != "操作成功") {
          cbError(res.msg);
        } else {
          cb();
        }
      });
    },
    //点击编辑||查看
    seequeryReport({ commit }, id) {
      queryReport(id).then((res) => {
        commit("SET_SEEQUERY", res.data);
      });
    },
    //排序
    sortTable({ dispatch }, { data, pageNum, pageSize, form, cb, cbError }) {
      setSequencereport(data).then((res) => {
        if (res.code == "200") {
          cb();
        } else {
          cbError()
        }
      })

    },
  },
};
export default allReports;
