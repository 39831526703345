import Vue from "vue";
import Vuex from "vuex";

import dashboard from "./modules/dashboard";
import analysisReport from "./modules/analysisReport";
import label from "./modules/label";
import journal from "./modules/journal";
import reportClassification from "./modules/reportClassification";
import allReports from "./modules/allReports";
import userManagement from './modules/userManagement';
import loading from './modules/loading';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appName: "analysis",
  },
  modules: {
    dashboard,
    analysisReport,
    label,
    journal,
    reportClassification,
    allReports,
    userManagement,
    loading
  },
});

