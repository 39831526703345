import { fetchApi} from "@/utils/axios";
import URL from "@/api/urlConfig"
// 获取dashboard
export function getDashboard(data) {
   return fetchApi(URL.DATA_ANALYSIS_DSBLIST, data, "POST", "body");
}

// 获取dashboard下拉框
export function getSelectAggregate(data) {
  return fetchApi(URL.DATA_ANALYSIS_DSBSELECT , data);
}
// 获取标签下拉框
export function getDropDownBox(data) {
  return fetchApi(URL.DATA_ANALYSIS_DROPDOWNBOX, data);
}
// 添加报告预览日志
export function logAddLog(data) {
  return fetchApi(URL.DATA_ANALYSIS_ADDLOG, data, "POST", "body");
}
// 看板中心列表
export function getKanbanList(data) {
  return fetchApi(URL.DATA_ANALYSIS_KANBAN, data, "POST", "body");
}
// 根据分类类型查询分类
export function getClassifyTypeList(data) {
  return fetchApi(URL.DATA_ANALYSIS_CLASSIFY_TYPE_LIST+ data.type);
}