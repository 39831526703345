import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"


// 获取用户
export function getUserInfo(data) {
    return fetchApi(URL.DATA_ANALYSIS_USERINFO, data);
}

//获取手机号
export function getUserPhone(data) {
    return fetchApi(URL.DATA_ANALYSIS_USERPHONE + data);
}

// 新增用户信息
export function addUserinsert(data) {
    return fetchApi(URL.DATA_ANALYSIS_USERINSERT, data, "POST", "body");
}

//获取当前修改用户信息
export function getUserFindById(id) {
    return fetchApi(URL.DATA_ANALYSIS_USERFINDBYID + id);
}

// 修改用户信息
export function userUpadate(data) {
    return fetchApi(URL.DATA_ANALYSIS_USERUPDATE, data, "POST", "body");
}

// 删除用户信息
export function userDeleted(data) {
    return fetchApi(URL.DATA_ANALYSIS_USERDELETED + data, {}, "POST", "params");
}

