import { getUserInfo, getUserPhone, addUserinsert, getUserFindById, userUpadate, userDeleted } from "@/api/dataAnalysis/user";

const label = {
    namespaced: true,
    state: () => ({
        userInfoData: [],
        total: 0,
        userPrimaryKey: [],
        userfindData: []
    }),
    mutations: {
        SET_USERINFODATA(state, list) {
            state.userInfoData = list;
        },
        SET_TOTAL(state, list) {
            state.total = list;
        },
        SET_USERPRIMARYKEY(state, list) {
            state.userPrimaryKey = list;
        },
        SET_USERFINDDATA(state, list) {
            state.userfindData = list;
        },

    },
    actions: {
        //初始化
        getUserInfoFn({ commit }, arg) {
            if (arg.userLableInfo && arg.userLableInfo.length > 0) {
                arg.userLableInfo = arg.userLableInfo.join(",");
            } else {
                delete arg.userLableInfo;
            }
            console.log(arg);
            return getUserInfo(arg).then((res) => {
                console.log(res);
                if (res.code == '200') {
                    for (let i = 0; i < res.data.list.length; i++) {
                        if (res.data.list[i].userLableList) {
                            res.data.list[i].userLableList = res.data.list[i].userLableList.join(',')
                        }
                        res.data.list[i].userStatus = res.data.list[i].userStatus == 0 ? "启用" : "禁用";
                    }

                    commit("SET_USERINFODATA", res.data.list);
                    commit("SET_TOTAL", res.data.total);
                }
            });
        },
        //用户账号查询
        getUserPhoneFn({ commit }, { usernumber, cb, cbError }) {
            return getUserPhone(usernumber).then((res) => {
                if (res.data.id) {
                    commit("SET_USERPRIMARYKEY", res.data);
                    cb()
                } else {
                    cbError()
                }
            });
        },
        //添加用户信息
        addUserinsertFn({ dispatch }, { form, cb, cbError }) {
            addUserinsert(form).then((res) => {
                console.log(res);
                if (res.code == '200') {
                    dispatch('getUserInfoFn', { pageNum: 1, pageSize: 20 })
                    cb()
                } else {
                    cbError(res.msg)
                }
            })
        },
        //获取当前修改用户信息
        getUserFindByIdFn({ commit }, { id, cb }) {
            getUserFindById(id).then((res) => {
                console.log(res);
                commit("SET_USERFINDDATA", res.data);
                setTimeout(() => {
                    cb()
                }, 10);
            })
        },

        //修改用户信息
        userUpadateFn({ dispatch }, { form, cb, cbError }) {
            userUpadate(form).then((res) => {
                console.log(res);
                if (res.code == "200") {
                    dispatch('getUserInfoFn', { pageNum: 1, pageSize: 20 })
                    cb()
                }
                else {
                    cbError(res.msg)
                }
            })
        },

        //删除
        userDeletedFn({ dispatch }, { id, cb, pageNum, pageSize, cbError }) {
            userDeleted(id).then((res) => {
                if (res.success != true) {
                    cbError(res.msg);
                } else {
                    dispatch('getUserInfoFn', { pageNum, pageSize })
                    cb();
                }
            });
        },
    },
};
export default label;
