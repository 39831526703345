import { manageList, manageInsert, manageUpdate, manageDeleted } from "@/api/dataAnalysis/reportClassification";

const reportClassification = {
  namespaced: true,
  state: () => ({
    list: [],
    total: 0,
  }),
  mutations: {
    SET_LOG(state, operationData) {
      state.list = operationData;
    },
    SET_TOTAL(state, totals) {
      state.total = totals;
    },
  },
  actions: {
    //初始化
    journalDate({ commit }, page) {
      manageList(page).then((res) => {
        commit("SET_LOG", res.data.list);
        commit("SET_TOTAL", res.data.total);
      });
    },
    // 新增
    manageInserts({ dispatch }, { form, pageNum, pageSize, cb, cbError }) {
      manageInsert(form).then((res) => {
        console.log(res
          );
        if (res.code == 200) {
          cb("添加成功");
          dispatch("journalDate", { pageNum, pageSize });
        } else {
          cbError(res.msg);
        }
      });
    },
    // 编辑
    manageUpdates({ dispatch }, { form, pageNum, pageSize,cb, cbError }) {
      manageUpdate(form).then((res) => {
        console.log(res);
        if (res.code == 200) {
          cb("修改成功")
          dispatch("journalDate", { pageNum, pageSize });
        } else {
          cbError(res.msg);
        }
      });
    },
    //删除
    manageDeleteds({ dispatch }, { labelId, cb, pageNum, pageSize, cbError }) {
      manageDeleted({ labelId }).then((res) => {
        if (res.msg=='操作成功') {
          dispatch("journalDate", { pageNum, pageSize });
          cb();
        } else {
          cbError(res.msg)
        }
      })
    },
  },
};
export default reportClassification;
