export default {
  // 用户管理
  userManage: {
    // 添加
    add: "userManagement_userManage_add",
    // 编辑
    edit: "userManagement_userManage_edit",
    // 删除
    delete: "userManagement_userManage_delete",
  }
}