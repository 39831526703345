import { getLabelGeometry, setLabelGeometry, modifyLabel, deleteLabelGeometry } from "@/api/dataAnalysis/label";

const label = {
  namespaced: true,
  state: () => ({
    dataArr: [],
    total: 0,
  }),
  mutations: {
    SET_DATAARR(state, list) {
      state.dataArr = list;
    },
    SET_TOTAL(state, list) {
      state.total = list;
    },
  },
  actions: {
    //初始化
    labelManagement({ commit }, page) {
      console.log(page);
      if (page.pageNum && page.pageSize) {
        var pageNums = page;
      } else {
        pageNums = { isAll: 1 }
      }
      return getLabelGeometry(pageNums).then((res) => {
        for (let i = 0; i < res.data.list.length; i++) {
          res.data.list[i].labelType = res.data.list[i].labelType == 0 ? "用户标签" : "报告标签";
        }
        commit("SET_DATAARR", res.data.list);
        commit("SET_TOTAL", res.data.total);
      });
    },
    //新增
    createLabel({ dispatch }, { form, pageNum, pageSize, cb, cbError }) {
      console.log(form);
      setLabelGeometry(form).then((res) => {
        console.log(res);
        if (res.code == 200) {
          cb("添加成功");
          dispatch("labelManagement", { pageNum, pageSize });
        } else {
          cbError(res.msg);
        }
      });
    },
    //修改
    modifyLabel({ dispatch }, { form, pageNum, pageSize, cb, cbError }) {
      modifyLabel(form).then((res) => {
        console.log(res);
        if (res.code == 200) {
          cb("修改成功");
          dispatch("labelManagement", { pageNum, pageSize });
        } else {
          cbError(res.msg);
        }
      });
    },
    //删除
    deleteLabel({ dispatch }, { labelId, cb, pageNum, pageSize, cbError }) {
      deleteLabelGeometry({ labelId }).then((res) => {
        console.log(res);
        if (res.success) {
          dispatch("labelManagement", { pageNum, pageSize });
          cb();
        } else {
          cbError(res.msg)
        }
      });
    },
  },
};
export default label;
