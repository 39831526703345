import { getDashboard, getSelectAggregate,getDropDownBox,logAddLog,getKanbanList,getClassifyTypeList} from "@/api/dataAnalysis/dashboard";
const dashboard = {
  namespaced: true,
  state: () => ({
    dashboardList: [],
    dropdownCollection: [],
    labelSelect:[],//标签下拉值
    total: 0,
    reportList:[],//看板中心
    reportTotal:0,//看板总数
    classTypeList:[]//分类类型下拉值
  }),
  mutations: {
    SET_DESHBOARDLIST(state, arg) {
      state.dashboardList = arg;
    },
    SET_TOTAL(state, arg) {
      state.total = arg;
    },
    SET_SELECT(state, arg) {
      state.dropdownCollection = arg;
    },
    //标签下拉框赋值
    SET_LABEL_SELECT(state, arg) {
      state.labelSelect = arg;
    },
    SET_BULLETIN_BOARD(state, arg) {
      state.reportList = arg;
    },
    SET_BULLETIN_BOARD_TOTAL(state, arg) {
      state.reportTotal = arg;
    },
    SET_CLASS_TYPE_LIST(state, arg) {
      state.classTypeList = arg;
    },

  },
  actions: {
    getLabelSelect({ commit }, data) {
      getDropDownBox(data).then((res) => {
         commit("SET_LABEL_SELECT", res.data);
      });
    },
    getKanbanList({ commit }, data) {
      getKanbanList(data).then((res) => {
        //从大到小排序
        res.data.list.sort((a, b) => {
          return b.sequence - a.sequence
        })
        commit("SET_BULLETIN_BOARD", res.data.list);
        commit("SET_BULLETIN_BOARD_TOTAL", res.data.total);
      });
    },
    getDashboard({ commit }, data) {
      getDashboard(data).then((res) => {
        //从大到小排序
        res.data.list.sort((a, b) => {
          return b.sequence - a.sequence
        })
        commit("SET_DESHBOARDLIST", res.data.list);
        commit("SET_TOTAL", res.data.total);
      });
    },
    getSelectList({ commit }, type) {
      getSelectAggregate(type).then((res) => {
        commit("SET_SELECT", res.data);
      });
    },
    getClassSelectList({ commit }, type) {
      getClassifyTypeList(type).then((res) => {
        commit("SET_CLASS_TYPE_LIST", res.data);
      });
    },
    checkLogAddLog({ commit }, type) {
      logAddLog(type).then((res) => {
        console.log(res)
      });
    },
  },
};
export default dashboard;
