import { getReportList } from "@/api/dataAnalysis/analysisReport";
const analysisReport = {
    namespaced: true,
    state: () => ({
        tableList: [],
        total: 0,
    }),
    mutations: {
        //table列表数据赋值
        SET_TABLE(state, arg) {
            state.tableList = arg;
        },
        SET_TOTAL(state, arg) {
            state.total = arg;
        },
    },
    actions: {
        getTable({ commit }, data) {
            getReportList(data).then((res) => {
                commit("SET_TABLE", res.data.list);
                commit("SET_TOTAL", res.data.total);
            });
        },
    },
};
export default analysisReport;
