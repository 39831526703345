import "./public-path";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import common from "qiankun-vue2-common";
import Antd from "ant-design-vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import validator from "validator";
import buttonPermissions from "@/utils/buttonPermissions"

Vue.prototype.$validator = validator;

import { message } from "ant-design-vue"

import sensors from "sa-sdk-javascript";
// 埋点
// 'http://data-warehouse.innoecos.cn:9001/sa?project=default'  测试
// 'http://data-warehouse.innoecos.cn:9001/sa?project=production' 生产
let pointsAddress = ''
if (process.env.NODE_ENV === 'production') {
  pointsAddress = 'http://data-warehouse.innoecos.cn:9001/sa?project=production';
} else {
  pointsAddress = 'http://data-warehouse.innoecos.cn:9001/sa?project=default';
}
sensors.init({
    server_url: pointsAddress,
    app_js_bridge: true,
    show_log :false, // 是否开始log日志
    heatmap: {
        //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
        clickmap: 'default',
        //是否开启触达注意力图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
        scroll_notice_map: 'not_collect',
    }
});
Vue.prototype.$sensors = sensors

// 校验按钮权限
Object.keys(buttonPermissions).forEach(key => {
  Vue.prototype[`$${key}`] = buttonPermissions[key];
})

message.config({
  top: `100px`,
  duration: 2,
  maxCount: 1,
});

Vue.config.productionTip = false;
let instance = null;
function render(props = {}) {
  const { container } = props;
  Vue.use(ElementUI, { size: "small" });
  Vue.use(Antd);
  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector("#app") : "#app");
}

export async function bootstrap(props) {
  console.log("[vue] vue app bootstraped", props);
}
export async function mount(props) {
  console.log("[vue] props from main framework", props);
  common.initGlobalState(store, props);
  render(props);
}
export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = "";
  instance = null;
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}
