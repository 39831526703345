import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

//获取信息
export function manageList(data) {
  return fetchApi(URL.DATA_ANALYSIS_CLASSIFICATION, data);
}
//新增分类
export function manageInsert(data) {
  return fetchApi(URL.DATA_ANALYSIS_CLASSINSERT, data, "POST", "body");
}
//修改分类
export function manageUpdate(data) {
  return fetchApi(URL.DATA_ANALYSIS_CLASSUPDATE, data, "POST", "body");
}

//删除分类
export function manageDeleted(labelId) {
  let id = labelId.labelId;
  return fetchApi(URL.DATA_ANALYSIS_CLASSDELETED + id, null, "POST", "body");
}
