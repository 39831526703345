import { getLogAggregate } from "@/api/dataAnalysis/journal";

const journal = {
  namespaced: true,
  state: () => ({
    Log: [],
    total: 0,
  }),
  mutations: {
    SET_LOG(state, operationData) {
      state.Log = operationData;
    },
    SET_TOTAL(state, totals) {
      state.total = totals;
    },
  },
  actions: {
    //初始化
    journalDate({ commit }, page) {
      getLogAggregate(page).then((res) => {
        console.log(res);
        commit("SET_LOG", res.data.list);
        commit("SET_TOTAL", res.data.total);
      });
    },
  },
};
export default journal;
