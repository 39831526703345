export default {
  // 标签管理
  tagManage: {
    // 添加
    add: "basicInformation_tagManage_add",
    // 编辑
    edit: "basicInformation_tagManage_edit",
    // 删除
    delete: "basicInformation_tagManage_delete",
  },
  // 操作日志
  actionLog: {
    // 预览
    look: "basicInformation_actionLog_look"
  }
}