import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"


//获取平台
export function getReports(data) {
  return fetchApi(URL.DATA_ANALYSIS_MANAGEMENTDATA, data);
}

//获取类型
export function getClassify(data) {
  return fetchApi(URL.DATA_ANALYSIS_MANAGEMENTCLASSIFY, data);
}

// 获取全部报告
export function getReoprtlist(data) {
  return fetchApi(URL.DATA_ANALYSIS_MANAGEMENTLIST, data, "POST", "body");
}

// 新增报告
export function addReport(data) {
  return fetchApi(URL.DATA_ANALYSIS_MANAGEMENTINSERT, data, "POST", "body");
}

// 修改报告
export function updateReport(data) {
  return fetchApi(URL.DATA_ANALYSIS_MANAGEMENTUPDATE, data, "POST", "body");
}

// 删除报告
export function deleteReport(data) {
  return fetchApi(URL.DATA_ANALYSIS_MANAGEMENTDELETED + data, {}, "POST", "params");
}

//查询报告
export function queryReport(data) {
  return fetchApi(URL.DATA_ANALYSIS_MANAGEMENTFINDBYID + data, {});
}

//排序
export function setSequencereport(data) {
  return fetchApi(URL.DATA_ANALYSIS_MANAGEMENTSORT + data.id + "/" + data.sequence, data, "POST", "body");
}
//列表中报告类型/报告平台
export function getMetadataList(data) {
  return fetchApi(URL.GETMETADATALIST , data);
}
