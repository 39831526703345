import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"


// 获取标签
export function getLabelGeometry(data) {
  return fetchApi(URL.DATA_ANALYSIS_LABLIST, data, "POST", "body");
}

// 新增标签
export function setLabelGeometry(data) {
  return fetchApi(URL.DATA_ANALYSIS_LABINSERT, data, "POST", "body");
}

// 修改标签
export function modifyLabel(data) {
  return fetchApi(URL.DATA_ANALYSIS_LABMODIFIED, data, "POST", "body");
}
//删除标签
export function deleteLabelGeometry(data) {
  return fetchApi(URL.DATA_ANALYSIS_LABDELETE, data, "DELETE");
}
