const loading = {
    namespaced: true,
    state: () => ({
      loading: false
    }),
    mutations: {
      UPDATE_LOADING_STATE(state, val) {
        state.loading= val;
      },
      
    },
    actions: {},
    getters: {
      isLoading: state => state.loading
    }
  };
  export default loading;
  