export default {
  // 分离管理
  categoryManage: {
    // 添加
    add: "reportManagement_categoryManage_add",
    // 编辑
    edit: "reportManagement_categoryManage_edit",
    // 删除
    delete: "reportManagement_categoryManage_delete"
  },
  // 报告管理
  reportManage: {
    // 添加
    add: "reportManagement_reportManage_add",
    // 编辑
    edit: "reportManagement_reportManage_edit",
    // 预览
    look: "reportManagement_reportManage_look",
    // 删除
    delete: "reportManagement_reportManage_delete"
  }
}